import GsuLoader from '@/components/GsuLoader'
import { PageMetaInfo } from '@/compositions/MetaInfo'
import { FragHomeMetaInfo } from '@/compositions/MetaInfo/query'
import { HandleOnLoadAction } from '@/compositions/OnLoadAction'
import { FragOnLoadAction } from '@/compositions/OnLoadAction/query'
import type { Props as PageComposerProps } from '@/compositions/PageComposer'
import { FragComposerSections } from '@/compositions/PageComposer/query'
import PageLayout from '@/compositions/PageLayout'
import { useFeatureToggle } from '@/context/featureToggles'
import { isValidReturnTo } from '@/lib/returnToUrl'
import { queryGsuApi } from '@/service/query'
import { getPartnerAttributionStateFromCookies } from '@/service/request'
import { MetaInfo, Page } from '@/shared/api-generated-types'
import type { GetServerSideProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { gql, useQuery } from 'urql'

// PageComposer dynamic import to prevent SSR hydration issues
const PageComposerWithLoader = dynamic<PageComposerProps>(() => import('@/compositions/PageComposer'), {
  ssr: false,
  loading: () => <GsuLoader consumeSpace />,
})

const QUERY_HOME = gql`
  ${FragComposerSections}
  ${FragHomeMetaInfo}
  ${FragOnLoadAction}

  query Home {
    page: staticPage(id: "home") {
      id
      catalogueLanguage
      title
      metaInfo {
        ...HomeMetaInfo
      }
      onLoadAction {
        ...OnLoadAction
      }
      sections {
        ...CoreSections
        ...RecursiveSections
      }
    }
    currentUser {
      authenticatedUser {
        id
      }
    }
    features(currentVersion: "", appName: "gsu-web") {
      id
      enabled
    }
  }
`

const QUERY_SECTIONS = gql`
  ${FragComposerSections}

  query Sections {
    sections: homepageSections(id: "home") {
      sections {
        ...CoreSections
        ...RecursiveSections
      }
    }
  }
`

const QUERY_PAGE_ONLOAD_ACTION_TO_COOKIE_CLEANUP = gql`
  ${FragOnLoadAction}

  query OnLoadActionCleanUpQuery {
    page: staticPage(id: "home") {
      id
      catalogueLanguage
      title
      onLoadAction {
        ...OnLoadAction
      }
    }
  }
`

interface PageData {
  page: Page
  metaInfo: MetaInfo
}

interface ExtraPageData {
  sections: {
    sections: Page['sections']
  }
}

const Home: NextPage = (_props) => {
  const [pageData, setPageData] = useState<Page | undefined>(undefined)
  const canSeeHomeAdditionalCategoryRows = useFeatureToggle('exp_homepage_category_rows')

  // Page request
  const [{ data: initialPageData, fetching, error }] = useQuery<PageData>({
    query: QUERY_HOME,
    requestPolicy: 'cache-first',
  })

  // Extra Page request
  const [{ data: extraPageData, fetching: fetchingExtras }] = useQuery<ExtraPageData>({
    query: QUERY_SECTIONS,
    requestPolicy: 'cache-first',
  })

  /**
   * ⚠️ It is important for this client-side request to be triggered.
   * This will allow GQL to clear necessary cookies.
   * The onLoadAction must still be passed into the props for the page to
   * actually *do* any onLoadAction. Such as displaying a dialog.
   */
  useQuery({ query: QUERY_PAGE_ONLOAD_ACTION_TO_COOKIE_CLEANUP })

  /* sections.sections <-- TODO, this should get de-nested. */
  useEffect(() => {
    if (initialPageData && !canSeeHomeAdditionalCategoryRows) {
      setPageData({
        ...initialPageData.page,
      })
    }
    if (initialPageData && extraPageData && canSeeHomeAdditionalCategoryRows) {
      const insertAtIndex = 6
      const combinedSections = [...initialPageData.page.sections]
      combinedSections.splice(insertAtIndex, 0, ...extraPageData.sections.sections)
      setPageData({
        ...initialPageData.page,
        sections: combinedSections,
      })
    }
  }, [initialPageData, extraPageData, canSeeHomeAdditionalCategoryRows])

  return (
    <PageLayout>
      {pageData && <PageMetaInfo {...pageData?.metaInfo} />}
      {pageData?.onLoadAction ? <HandleOnLoadAction {...pageData?.onLoadAction} /> : null}
      <PageComposerWithLoader {...pageData} fetching={fetching || fetchingExtras} error={error} />
    </PageLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale = 'en-US', req, query }) => {
  // For handling post-authentication redirection to returnTo URL
  if (typeof query?.returnTo === 'string' && isValidReturnTo(query.returnTo)) {
    return {
      redirect: {
        permanent: false,
        destination: query.returnTo,
      },
    }
  }

  // For users attributed to a partner, home page redirects to their attributed partner page.
  const partnerState = getPartnerAttributionStateFromCookies(req.cookies)

  if (partnerState && partnerState.slug) {
    const localePath = locale !== 'en-US' ? `/${locale}` : ''
    return {
      redirect: {
        permanent: false,
        destination: `${localePath}/partner/${partnerState.slug}`,
      },
    }
  }

  const [urqlState, , data] = await queryGsuApi(QUERY_HOME, {}, req)
  const isAuthenticatedUser = data?.currentUser.authenticatedUser
  const canSeeExpHomepage = data?.features.find((x: { id: string }) => x.id === 'exp_homepage_ab_testing')

  // If the user is included in the toggle & is not logged in, show them the new page
  if (canSeeExpHomepage?.enabled && !isAuthenticatedUser) {
    return {
      redirect: {
        permanent: false,
        destination: `/exp-home`,
      },
    }
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['badge', 'common', 'classes', 'series'])),
      urqlState,
    },
  }
}

export default Home
